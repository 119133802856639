import { BACKEND_DOMAIN, BRAND_DOMAIN } from '../constants/Branding';

export const ProdUrlsMap = {
  REACT_APP_REST_API_URL: `https://api.${BACKEND_DOMAIN}/api/v1`,
  REACT_APP_REST_API_V2: `https://api.${BACKEND_DOMAIN}`,
  REACT_APP_WEBSOCKET_URL_BETFAIR: `https://feed.mysportsfeed.io/odds-feed`,
  REACT_APP_WEBSOCKET_URL_DREAM: `https://feed.mysportsfeed.io/odds-feed`, //BM&Fancy
  REACT_APP_WEBSOCKET_URL: `https://feed.mysportsfeed.io/odds-feed`, //BM&Fancy
  BINARY_WEBSOCKET_URL: `https://feed.mysportsfeed.io/odds-feed`,
  REACT_APP_AGPAY_API_URL: `https://agpay.${BACKEND_DOMAIN}`,
  REACT_APP_REST_REPORTING_API_URL: `https://reporting.${BACKEND_DOMAIN}`,
};

export function getBaseUrl(url: string) {
  return ProdUrlsMap[url];
}
