import React from 'react';
import './LoginPage.scss';
import LoginCard from '../../components/LoginForm/LoginForm';
import Card from '@material-ui/core/Card';
import webtitleImg from '../../assets/images/brand/title.png';
import { IonImg } from '@ionic/react';

const LoginPage: React.FC = () => {
  const modalCloseHandler = () => {};

  return (
    <div className="bg">
      <div className="brand-logo">
          <img className="brand-logo-img" src={webtitleImg} />
        </div>
      <Card className="card">
        <div className="login-form">
          <LoginCard modalCloseHandler={modalCloseHandler} />
        </div>
      </Card>
    </div>
  );
};

export default LoginPage;
