import API from '../../api';
import { logout } from '../auth/authActions';

import {
  IS_ADMIN_REPORTS_URL,
  IS_ADMIN_RISKMGMT_URL,
  IS_HOUSE_URL,
  TOGGLE_DARK_MODE,
  FETCH_BETTING_CURRENCY_SUCCESS,
  FETCH_BETTING_CURRENCY_FAILED,
  CASINO_GAME_SELECTED,
  SET_LIVESTREAM_URL,
  SET_ALLOWED_CONFIG,
  ENABLE_COMMISSION,
} from './commonActionTypes';

import { CurrencyDetails } from '../../models/CurrencyDetails';
import { Currency } from '../../models/Currency';

export const activateReportsTab = (value: boolean) => {
  return {
    type: IS_ADMIN_REPORTS_URL,
    payload: value,
  };
};

export const activateRiskMgmtTab = (value: boolean) => {
  return {
    type: IS_ADMIN_RISKMGMT_URL,
    payload: value,
  };
};

export const activateHouseTab = (value: boolean) => {
  return {
    type: IS_HOUSE_URL,
    payload: value,
  };
};

export const toggleDarkMode = (value: boolean) => {
  return {
    type: TOGGLE_DARK_MODE,
    payload: value,
  };
};

const fetchBettingCurrencySuccess = (currency: CurrencyDetails) => {
  return {
    type: FETCH_BETTING_CURRENCY_SUCCESS,
    payload: currency,
  };
};

const fetchBettingCurrencyFailed = () => {
  return {
    type: FETCH_BETTING_CURRENCY_FAILED,
  };
};

export const enableCommission = (commission: boolean) => {
  return {
    type: ENABLE_COMMISSION,
    payload: commission,
  };
};

export const fetchBettingCurrency = () => {
  return async (dispatch: Function) => {
    try {
      // const response = await API.get('/user/betting-currency', {
      //   headers: {
      //     Authorization: sessionStorage.getItem('jwt_token'),
      //   },
      // });
      // dispatch(fetchBettingCurrencySuccess(response.data));

      //Return state data
      const response: CurrencyDetails = {
        bettingCurrency: Currency.Pts,
        currenciesAllowed: 0
      };
      dispatch(fetchBettingCurrencySuccess(response));

    } catch (err) {
      dispatch(fetchBettingCurrencyFailed());
      if (err.response && err.response.status === 401) {
        const token = sessionStorage.getItem('jwt_token');
        if (token) {
          dispatch(logout());
        }
      }
    }
  };
};

export const casinoGameSelected = (casinoGame: {
  id: string;
  name: string;
}) => {
  return {
    type: CASINO_GAME_SELECTED,
    payload: casinoGame,
  };
};

export const setLivestreamUrl = (url: string) => {
  return {
    type: SET_LIVESTREAM_URL,
    payload: url,
  };
};

export const setAllowedConfig = (allowedConfig: number) => {
  return {
    type: SET_ALLOWED_CONFIG,
    payload: allowedConfig,
  };
};
