import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import './Modal.scss';

type ModalProps = {
  closeHandler: Function;
  open: boolean;
  title: string;
  className?: string;
  size: 'lg' | 'md' | 'sm' | 'xl' | 'xs';
};

const Modal: React.FC<ModalProps> = (props) => {
  const { children, closeHandler, open, title, className, size } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(size));

  const handleClose = () => {
    closeHandler();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth={size}
      fullWidth={true}
      className={className ? className : null}
    >
      <>
        <DialogTitle className="modal-title" id="responsive-dialog-title">
          {title}
        </DialogTitle>
        {title.toLowerCase().includes('login') ? null : (
          <IconButton
            aria-label="close"
            className="modal-close-btn"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        )}
      </>

      <DialogContent className="modal-content-ctn">{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;
